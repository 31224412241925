<template>
  <div>
    <b-card-code title="Add Term" :before-change="submit">
      <b-card-text>
        <span>Add New Term </span>
      </b-card-text>
      <validation-observer ref="simpleRules">
        <b-form novalidate class="needs-validation" @submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-row v-for="(item, index) in languageSelect" :key="index">
                <!-- translation language -->
                <b-col md="5">
                  <validation-provider
                    #default="{ errors }"
                    name="language"
                    rules="required"
                  >
                    <b-form-group
                      label="Language"
                      label-for="language"
                      :state="errors.length > 0 ? false : null"
                    >
                      <v-select
                        :id="'language' + index"
                        v-model="languageSelect[index]"
                        :state="languageSelect[index] === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="lanuageOptions"
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Name -->
                <b-col md="5">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="input-valid1"
                        v-model="sedData.translations[index].name"
                        :state="errors.length > 0"
                        placeholder="Valid input"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="2" class="mb-50">
                  <b-button
                    v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                    v-show="!languageSelect[index].disa"
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)"
                  >
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
                <!-- add new button -->
              </b-row>
            </b-col>
            <b-col sm="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                block
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Add New</span>
              </b-button>
            </b-col>
            <!-- submit button -->
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                @click="submit"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>

import { required } from '@validations'
import { codeValidateTooltips } from './code'
export default {

  data() {
    return {
      required,
      success_message: '',
      showDismissibleAlert: false,
      showSuccessleAlert: false,
      errors_back: [],
      languageSelect: [
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      lanuageOptions: [
        {
          value: 'select_value',
          text: 'Select Value',
        },
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      sedData: {
        translations: [
          {
            locale: '',
            name: '',
          },
        ],
      },
      codeValidateTooltips,
    }
  },
  methods: {
    // submit function to add new term
    submit() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          for (let trn in this.sedData.translations) {
            this.sedData.translations[trn].locale = this.languageSelect[
              trn
            ].value
          }
          this.showDismissibleAlert = false
          this.showSuccessleAlert = false

          axios
            .post('terms', this.sedData)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push('/Terms')
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    repeateAgain() {
      this.data.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.languageSelect.push({})
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.translations.splice(index, 1)
        this.languageSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
}
</script>
<style lang="scss">
 
</style>
